@use "@angular/material" as mat;
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

$default-font-family: Roboto, "Helvetica Neue", sans-serif !important;

$custom-typography: mat.define-typography-config(
  $font-family: "inherit",
);
// @include mat-base-typography($custom-typography);
// @include mat-checkbox-typography($custom-typography);
// @include angular-material-typography($custom-typography);
// @include mat-core($custom-typography);
@include mat.core();
@include mat.typography-hierarchy($custom-typography);

$background-color: #f1f4f6;

$BorderRadius: 8px;
$PrimaryColor: #db752f;
$PrimaryDarkColor: darken($PrimaryColor, 5%);
$PrimaryDarkenColor: darken($PrimaryColor, 10%);
$PrimaryLighterColor: lighten($PrimaryColor, 10%);
$PrimaryGradient: $PrimaryColor;
$PrimaryLight: lighten($PrimaryColor, 5%);
$SecondaryColor: #30a9b2;
$SecondaryDarkColor: darken($SecondaryColor, 10%);
$SecondaryLighterColor: lighten($SecondaryColor, 10%);
$SecondaryGradient: $SecondaryColor;
$TertiaryColor: #374b58;
$Green: #368b85;
$GreenRgb: 54, 139, 133;
$Red: #ff4a4a;
$Orange: #ff844a;
$Blue: #4acaff;
$HyperLink: #567cd7;

$Gray-100: #f6f7f8;
$Gray-200: #ebecef;
$Gray-300: #ced2d9;
$Gray-400: #b2b8c2;
$Gray-500: #959eac;
$Gray-600: #788396;
$Gray-700: #606a7b;
$Gray-800: #4a515e;
$Gray-900: #333942;
$Gray-Black: #000000;
$Gray-White: #ffffff;
$Gray-Bg: #ffffff;

$md-opt1-secondary: (
  50: #e8f6f6,
  100: #c5e9e9,
  200: #9edadb,
  300: #77cbcd,
  400: #5abfc2,
  500: #3db4b7,
  600: #37adb0,
  700: #2fa4a7,
  800: #279c9f,
  900: #1a8c90,
  A100: #c8fdff,
  A200: #95fbff,
  A400: #62faff,
  A700: #48f9ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-opt1-primary: (
  50: #e4e3ea,
  100: #bcbaca,
  200: #8f8ca7,
  300: #625d84,
  400: #403b69,
  500: #1e184f,
  600: #1a1548,
  700: #16113f,
  800: #120e36,
  900: #0a0826,
  A100: #6a63ff,
  A200: #3930ff,
  A400: #0b00fc,
  A700: #0a00e2,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

//SMRT
$smrt-primary: mat.define-palette($md-opt1-primary);
$smrt-accent: mat.define-palette($md-opt1-secondary);
$smrt-warn: mat.define-palette(mat.$red-palette);
$smrt-theme: mat.define-light-theme(
  (
    color: (
      primary: $smrt-primary,
      accent: $smrt-accent,
      warn: $smrt-warn,
    ),
    typography: $custom-typography
  )
);
.smrt-theme {
  @include mat.all-component-themes($smrt-theme);
}

